import React                from "react"
import Img                  from "gatsby-image"
import { map }              from 'lodash-es'
import RawHtml              from 'entrada-ui/RawHtml'
import PageTitle            from 'components/PageTitle'
import Seo                  from 'components/Seo'
import {
    Col,
    Row,
    Container
}                             from 'react-bootstrap'
import cx                     from 'utils/classnames'
import Link                   from 'components/Link'

const ListingPageTemplate = ({ pageContext: { data } }) => {
  const {
    content,
    slug,
    siteMetadata,
    title,
    wordpress_id,
    acf: {
        featured_image,
        related_contents
    },
    yoast_meta
  } = data

  return (
    <>
      <Seo meta={yoast_meta} siteMetadata={siteMetadata} />
      <PageTitle title={title} />
      {featured_image &&
      <Container className="generic-hero-container">
        <Img fluid={featured_image.localFile.childImageSharp.fluid} className="generic-hero" />
      </Container>
      }

      <Container className="generic-container">
        <RawHtml html={content} />
      </Container>

      {/* TODO: create a 'PageCard' component for this below */}
      <Container className="related-pages-container">
        <Row className="related-pages">

          {map(related_contents, (item, idx) => (
          // TODO: add variations if less than 4 items
          <Col xs={12} md={6} xl={3} key={idx}>
            <div className="related-page-card">
              {item.acf.featured_image
                ? <Link to={item.path}><Img fluid={item.acf.featured_image.localFile.childImageSharp.fluid} className="related-page-img" /></Link>
                : <Link to={item.path}><div className="related-page-empty-img"></div></Link>
              }
              <div className="related-page-info">
                <Link className="related-page-link" to={item.path}>
                  {/* TODO: fix these 'replace' below */}
                  {item.title.replace('&#038;', '&').replace('&#39', "'").replace('&#8216;', "'").replace('&#8216;', "'").replace('&#8211;', '-').replace('&#8211;', '-').replace('&#8217;', "'").replace('&#8217;', "'")}
                </Link>
                {item.excerpt &&
                <RawHtml className="related-page-excerpt" html={item.excerpt} />
                }
              </div>
            </div>
          </Col>
          ))}

        </Row>
      </Container>
    </>
  )
}
export default ListingPageTemplate
